import { useLocation } from '@remix-run/react';
import { getLangSegment, useLocale } from '#app/utils/i18n.ts';

export function useChangeLanguageURL(): {
	to: string;
	locale: string;
	langSegment: string;
} {
	const locale = useLocale();
	const location = useLocation();
	const langSegment = getLangSegment(locale);
	const alternateLocale = locale === 'en' ? 'es' : 'en';
	const alternateLangSegment = getLangSegment(alternateLocale);

	const isRoot = langSegment === '';
	let rest = location.pathname;
	if (!isRoot) {
		rest = rest.split('/').slice(2).join('/');
	}
	let to = `${alternateLangSegment || '/'}`;
	if (rest) {
		to += `${rest}`;
	}

	return {
		to,
		locale,
		langSegment,
	};
}
